<header>
    <a href="home" class="logo">
        <svg class="logo" viewbox="0 0 110 60" xmlns="http://www.w3.org/2000/svg">
            <rect class="logobox" rx="6" ry="6" fill="black" x="0" y="0" width="110" height="60" />
            <polygon class="logoletters" fill="white"
                points="10,10 50,10 50,50 40,50 40,20 30,20 30,50 20,50 20,20 10,20 10,10" />
            <polygon class="logoletters" fill="white"
                points="60,10 70,10 70,40 80,40 80,10 90,10 90,40 100,40 100,50 60,50 60,10" />
        </svg>
    </a>
    <div>
        <button class="header__button nav-btn-js" type="button"></button>
        <nav class="header__nav nav-js" data-active="false">
            <a href="#" class="logo logo--white">
                <svg class="logo" viewbox="0 0 110 60" xmlns="http://www.w3.org/2000/svg">
                    <rect class="logobox" rx="6" ry="6" fill="black" x="0" y="0" width="110" height="60" />
                    <polygon class="logoletters" fill="white"
                        points="10,10 50,10 50,50 40,50 40,20 30,20 30,50 20,50 20,20 10,20 10,10" />
                    <polygon class="logoletters" fill="white"
                        points="60,10 70,10 70,40 80,40 80,10 90,10 90,40 100,40 100,50 60,50 60,10" />
                </svg>
            </a>
            <ul class="header__menu">
                <li class="header__menu-item"><a href="https://github.com/tillstud" target="_blank">GitHub</a></li>
                <li class="header__menu-item"><a href="https://www.linkedin.com/in/till-studer/" target="_blank">LinkedIn</a></li>
                <li class="header__menu-item"><a href="home">Home</a></li>
            </ul>
        </nav>
    </div>
</header>