<div class="wrapper">
    <div class="card-container">
        <div class="card">
            <a href="nightstand">
                <div class="card--display">
                    <h2>[WIP] - Infinity Mirror Nightstand</h2>
                    <br>
                    <br>
                    <i class="fas fa-ring fa-5x"></i>
                </div>
                <div class="card--hover">
                    <h2>[WIP] - Infinity Mirror Nightstand</h2>
                    <p>
                        This is an old project of mine which I digitalized and expanded a bit by adding a 3D model of the Nightstand I built.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>

    <div class="card-container">
        <div class="card">
            <a href="career">
                <div class="card--display">
                    <h2>[WIP] - Career timeline</h2>
                    <br>
                    <br>
                    <i class="fas fa-briefcase fa-5x"></i>
                </div>
                <div class="card--hover">
                    <h2>[WIP] - Career timeline</h2>
                    <p>
                        This is a timeline of all my work experiences and educational path.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>


    <div class="card-container">
        <div class="card">
            <a href="todo-goal-tracker">
                <div class="card--display">
                    <h2>[WIP] - ToDo & Goal Tracker</h2>
                    <br>
                    <br>
                    <i class="fas fa-clipboard-list fa-5x"></i>
                </div>
                <div class="card--hover">
                    <h2>[WIP] - ToDo & Goal Tracker</h2>
                    <p>
                        This is a little app which lets me organize my ToDo's and Goals.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>

    <div class="card-container">
        <div class="card">
            <a href="ics-generator">
                <div class="card--display">
                    <h2>[WIP] - iCalender generator</h2>
                    <br>
                    <br>
                    <i class="fas fa-calendar-day fa-5x"></i>
                </div>
                <div class="card--hover">
                    <h2>[WIP] - iCalender generator</h2>
                    <p>
                        This is a little app which lets you create .ics files to share with anybody.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>

    <div class="card-container">
        <div class="card">
            <a href="spellchecker">
                <div class="card--display">
                    <h2>[WIP] - Spell checker</h2>
                    <br>
                    <br>
                    <i class="fas fa-spell-check fa-5x"></i>
                </div>
                <div class="card--hover">
                    <h2>[WIP] - Spell checker</h2>
                    <p>
                        This is a little app which lets me fix my spelling mistakes from any device, with the comfort of knowing that none of the input data is stored.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>

    <!-- <div class="card-container">
        <div class="card">
            <a href="phonesoff">
                <div class="card--display">
                    <h2>Title</h2>
                </div>
                <div class="card--hover">
                    <h2>Title</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at est orci. Nam molestie
                        pellentesque mi nec lacinia. Cras volutpat arcu sit amet elit sodales, nec volutpat velit
                        bibendum.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div>

    <div class="card-container">
        <div class="card card--dark">
            <a href="phonesoff">
                <div class="card--display">
                    <h2>Title</h2>
                </div>
                <div class="card--hover">
                    <h2>Title</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at est orci. Nam molestie
                        pellentesque mi nec lacinia. Cras volutpat arcu sit amet elit sodales, nec volutpat velit
                        bibendum.
                    </p>
                    <p class="link">Click to see project</p>
                </div>
            </a>
            <div class="card--border"></div>
        </div>
    </div> -->

</div>